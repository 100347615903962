import "core-js/es/map";
import "core-js/es/set";
import "./polyfill";

import React from "react";
import ReactDOM from "react-dom";
import SafeAreInsets from "safe-area-insets";
import BlockApp from "./BlockApp";
import * as serviceWorker from "./serviceWorker";
import "./index.scss";

const AppWrapper = React.lazy(() => import("./AppWrapper"));

function initApp() {
  const root_el = document.getElementById("root");
  ReactDOM.render(
    <React.StrictMode>
      {window.cordova && typeof Proxy !== "function" ? (
        <BlockApp />
      ) : (
        <React.Suspense fallback={null}>
          <AppWrapper />
        </React.Suspense>
      )}
    </React.StrictMode>,
    root_el
  );

  if (window.cordova) {
    if (window.device?.platform === "Android") {
      hideNavigation();
      document.addEventListener("resume", hideNavigation, false);
    } else {
      window.is_ios = true;
      root_el?.classList.add("ios");
    }
  }
  window.game_type = (process.env.GAME_TYPE as any) || "5_CARDS";
}
function hideNavigation() {
  window.AndroidFullScreen.setSystemUiVisibility(
    window.AndroidFullScreen.SYSTEM_UI_FLAG_HIDE_NAVIGATION |
      window.AndroidFullScreen.SYSTEM_UI_FLAG_IMMERSIVE_STICKY
  );
}

if (window.cordova) {
  document.addEventListener("deviceready", initApp, false);
} else {
  initApp();
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
