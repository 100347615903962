import React from "react";
import "@src/components/Game/game.scss";

export default function () {
  const navigate = () => {
    if (
      window.cordova &&
      window.cordova.plugins &&
      window.cordova.plugins.market
    ) {
      window.cordova.plugins.market.open("com.google.android.webview");
    }
  };

  const is_android = window.device?.platform === "Android";

  return (
    <div className="game">
      <div className="game__background">
        <div className="game__background-img"></div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          height: "100%",
        }}
      >
        {is_android ? (
          <React.Fragment>
            <h2 style={{ marginBottom: "20px", padding: "20px" }}>
              5 Cards is not supported in old WebViews. Please update your
              WebView
            </h2>
            <button className="close-button" onClick={navigate}>
              Update
            </button>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <h2 style={{ marginBottom: "20px", padding: "20px" }}>
              Please update your iOS
            </h2>
          </React.Fragment>
        )}
      </div>
    </div>
  );
}
